import {
  REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION,
  REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS,
  REACT_APP_SUPER_CLIENTS,
  REACT_APP_ENABLE_CUSTOMER_LIST,
  REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_PF_ADMIN_ID,
  REACT_APP_CONFIGURATION_AUDIT_LOG_ENABLED,
  REACT_APP_AUDIT_TAB_ENABLED,
  REACT_PF_CSV_UPLOAD_SIZE,
} from './constants/envConstants';
import { URL_CONSTANTS } from './constants/urlConstants';

const clientDetails = JSON.parse(
  localStorage.getItem('client_details') || '{}'
);

export const CLIENT_DETAILS = Object.freeze({
  ...clientDetails,
});
export const ALLOWED_CLIENTS_FOR_WIDGET_CREATION =
  REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION
    ? JSON.parse(REACT_APP_ALLOWED_CLIENTS_FOR_WIDGET_CREATION)
    : [];

export const VIEW_DASHBOARD_PERFORMANCE_USERS =
  REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS
    ? REACT_APP_VIEW_DASHBOARD_PERFORMANCE_USERS.split(',')
    : [];

export const COVERAGE_BY = {
  ZIPCODE: 1,
  STORE: 2,
  DISTRICT: 3,
};

export const QUICK_ACTION_TYPE = {
  BUSTED_JOB: 'busted-job',
  BUSTED_TITLE: 'Busted Reason',
  NOTES_TITLE: 'Note',
  READY_TO_SCHEDULE: 'ready-to-schedule',
};

export const Windows = {
  '8:00 AM - 5:00 PM': '8:00 AM - 5:00 PM',
  '8:00 AM - 10:00 AM': '8:00 AM - 10:00 AM',
  '9:00 AM - 11:00 AM': '9:00 AM - 11:00 AM',
  '10:00 AM -12:00 PM': '10:00 AM -12:00 PM',
  '11:00 AM - 1:00 PM': '11:00 AM - 1:00 PM',
  '12:00 PM - 2:00 PM': '12:00 PM - 2:00 PM',
  '1:00 PM - 3:00 PM': '1:00 PM - 3:00 PM',
  '2:00 PM - 4:00 PM': '2:00 PM - 4:00 PM',
  '3:00 PM - 5:00 PM': '3:00 PM - 5:00 PM',
  '4:00 PM - 6:00 PM': '4:00 PM - 6:00 PM',
  '5:00 PM - 7:00 PM': '5:00 PM - 7:00 PM',
  '6:00 PM - 8:00 PM': '6:00 PM - 8:00 PM',
  '7:00 PM - 9:00 PM': '7:00 PM - 9:00 PM',
};

export const SUPER_CLIENTS = REACT_APP_SUPER_CLIENTS
  ? JSON.parse(REACT_APP_SUPER_CLIENTS)
  : [];

// ENUM for Yes/No Dropdown
export const YesNoDropdown = [
  {
    id: 1,
    value: 'Yes',
  },
  {
    id: 0,
    value: 'No',
  },
];

export const PropertyOccupationDropdown = [
  {
    key: 'Owner',
    value: 'Owner',
  },
  {
    key: 'Tenant',
    value: 'Tenant',
  },
];

// ENUM for Lead Test Dropdown
export const LeadTest = [
  {
    id: 0,
    value: 'Negative',
  },
  {
    id: 1,
    value: 'Positive',
  },
];

// ENUM for status colors
export const StatusColors = {
  PF_GREEN: '#2CAA42',
  PF_YELLOW: '#FF8800',
  PF_RED: '#FF0D00',
  PF_LIGHT_RED: '#FFC3C1',
  PF_LIGHT_GREEN: '#DDF7D5',
  PF_LIGHT_YELLOW: '#FFF3CD',
  PF_GRAY: '#777e98',
  PF_RED_ORANGE: '#f44336',
  PF_PALE_YELLOW: '#FBE253',
};

export const Colors = {
  PF_GREYBORDER: '#CCCCCC',
  PF_BORDER: '#E5E8EB',
  PF_WIDGETCOLOR: '#B9BCBF',
  PF_WHITE: '#FFFFFF',
  PF_GRAY: '#BDBDBD',
  PF_BLACK: ' #000000',
  PF_BLUE: '#0000ff',
};
// Options for Search Params
export const GLOBAL_SEARCH_PARAMETERS_OPTIONS = [
  {
    label: 'Project#',
    value: 'project_number',
  },
  {
    label: 'PO#',
    value: 'po_number',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Address',
    value: 'address',
  },
];

// Options for Widget Type
export const WidgetTypeOptions = [
  {
    key: 'bar-chart',
    value: 'Bar Chart',
  },
  {
    key: 'external-link-button',
    value: 'Button',
  },
  {
    key: 'card',
    value: 'Card',
  },
  {
    key: 'gauge',
    value: 'Gauge',
  },
  {
    key: 'line-chart',
    value: 'Line',
  },
  {
    key: 'pie-chart',
    value: 'Pie Chart',
  },
  {
    key: 'table-report',
    value: 'Tabular Report',
  },

  // {
  //   key: 'button-report',
  //   value: 'Link Button',
  // },
  // {
  //   key: 'tabular-report',
  //   value: 'Tabular Button',
  // },
];

// Options for Menu List
export const getMenuList = context => {
  return [
    {
      route: '/dashboard',
      value: 'Home',
      icon: 'Home',
      subMenu: [],
      permission: 'HOME_M',
    },
    {
      route: '',
      value: 'Project',
      icon: 'Project',
      subMenu: [
        {
          route: '/scheduler',
          value: 'Scheduler',
          subMenu: [],
          permission: 'SCHEDULER_M',
        },
        {
          route: '/project/clients',
          value: 'Clients',
          subMenu: [],
          permission: 'CLIENTS_M',
        },
        {
          route: '/project/items',
          value: 'Items',
          subMenu: [],
          permission: 'ITEMS_M',
        },
        {
          route: '/project/add',
          value: 'Add Project',
          subMenu: [],
          permission: 'ADD_PROJECT',
        },
        {
          route: '/project/add-fields',
          value: 'Add Project Fields',
          subMenu: [],
          permission: 'ADD_PROJECT_FIELDS_VIEW',
        },
        {
          route: '/payment-request',
          value: 'Payment Request',
          subMenu: [],
          permission: 'ADD_PROJECT',
        },
      ],
      permission: 'PROJECT_M',
    },
    {
      route: '',
      value: 'Admin',
      icon: 'Admin',
      subMenu: [
        {
          route: '/admin/laborprofiles',
          value: 'Labor Profiles',
          subMenu: [],
          permission: 'LABOR_PROFILES_M',
        },
        {
          route: '/admin/usermanagement',
          value: 'User Management',
          subMenu: [],
          permission: 'USER_MGMT_M',
        },
        {
          route: '/admin/stores',
          value: 'Stores',
          subMenu: [],
          permission: 'STORE_M',
        },
        {
          route: '/admin/dashboards',
          value: 'Dashboards',
          subMenu: [],
          permission: 'DASHBOARD_MGMT_M',
        },
        {
          route: '/admin/widgets',
          value: 'Widgets',
          subMenu: [],
          permission: 'DASHBOARD_WIDGET_TEMPLATE',
        },
        {
          route: '/admin/documentcenter',
          value: 'Document Center',
          subMenu: [],
          permission: 'DOC_CENTER_M',
        },
        {
          route: '/admin/ruleenginelogs',
          value: 'Event Logs',
          subMenu: [],
          permission: 'RULE_ENGINE_LOGS_VIEW',
        },
        // {
        //   route: '/admin/engine-result',
        //   value: 'Engine Result',
        //   subMenu: [],
        //   permission: 'RULE_ENGINE_LOGS_VIEW',
        // },
        {
          route: '/admin/tenant-billing',
          value: 'Tenant Billing',
          subMenu: [],
          permission: 'CONFIGURATION_M',
        },
        {
          route: '/admin/configuration',
          value: 'Configuration',
          subMenu: [],
          permission: 'CONFIGURATION_M',
        },
        {
          route: '/admin/checklist',
          value: 'Checklist',
          subMenu: [],
          permission: 'QUESTIONNAIRE_VIEW',
        },
        {
          route: '/admin/bulletin',
          value: 'Bulletin',
          subMenu: [],
          permission: 'CONFIGURATION_M',
        },
        {
          route: '/admin/chargeback/add',
          value: 'Add Chargeback',
          subMenu: [],
          permission: 'ADD_APPROVE_CHARGEBACK',
        },
      ],
      permission: 'ADMIN_M',
    },
    {
      route: '',
      value: 'Personnel',
      icon: 'Project',
      subMenu: [
        {
          route: '/personnel/availabilityupdates',
          value: 'Availability Updates',
          subMenu: [],
          permission: 'AVAILABILITY_UPDATE_M',
        },
      ],
      permission: 'PERSONNEL_M',
    },
  ];
};

// Colors for button
export const ButtonColors = [
  { label: 'Blue', value: '#1D25D5' },
  { label: 'Green', value: '#2CAA42' },
  { label: 'Yellow', value: '#FF8800' },
  { label: 'Red', value: '#FF0D00' },
  { label: 'Light Red', value: '#FFC3C1' },
  { label: 'Light Green', value: '#DDF7D5' },
  { label: 'Light Yellow', value: '#FFF3CD' },
];

// Options for measures operator
export const MeasuresOperators = [
  {
    key: 'avg',
    value: 'Average',
  },
  {
    key: 'min',
    value: 'Min',
  },
  {
    key: 'max',
    value: 'Max',
  },
  {
    key: 'sum',
    value: 'Sum',
  },
  {
    key: 'count',
    value: 'Count',
  },
];

// Options for filters operator
export const FiltersOperators = [
  {
    key: 'equals',
    value: 'Equals',
    type: 'string',
  },
  {
    key: 'notEquals',
    value: 'Not Equals',
    type: 'string',
  },
  {
    key: 'contains',
    value: 'Contains',
    type: 'string',
  },
  {
    key: 'notContains',
    value: 'Not Contains',
    type: 'string',
  },
  {
    key: 'set',
    value: 'Not Empty',
    type: 'string',
  },
  {
    key: 'notSet',
    value: 'Empty',
    type: 'string',
  },
  {
    key: 'beforeDate',
    value: 'Before Date',
    type: 'time',
  },
  {
    key: 'afterDate',
    value: 'After Date',
    type: 'time',
  },
];

export const NumberFiltersOperators = [
  {
    key: 'equals',
    value: 'Equals',
    type: 'string',
  },
  {
    key: 'notEquals',
    value: 'Not Equals',
    type: 'string',
  },
  {
    key: 'set',
    value: 'Not Empty',
    type: 'string',
  },
  {
    key: 'notSet',
    value: 'Empty',
    type: 'string',
  },
  {
    key: 'gt',
    value: 'Greater Than',
    type: 'string',
  },
  {
    key: 'gte',
    value: 'Greater Than Equals To',
    type: 'string',
  },
  {
    key: 'lt',
    value: 'Less Than',
    type: 'string',
  },
  {
    key: 'lte',
    value: 'Less Than Equals To',
    type: 'string',
  },
];

export const FiltersOperatorsForDate = [
  {
    key: 'equals',
    value: 'Equals',
    type: 'string',
  },
  {
    key: 'notEquals',
    value: 'Not Equals',
    type: 'string',
  },
  {
    key: 'beforeDate',
    value: 'Before Date',
    type: 'time',
  },
  {
    key: 'afterDate',
    value: 'After Date',
    type: 'time',
  },
];

export const cubeIdsMapping = {
  'Customer.customer_id': '# of Customers',
  'InstallerDetail.installer_id': '# of installers',
  'Project.client_id': '# of Projects',
  'ProjectItem.project_item_id': '# of Project items',
  'Store.store_id': '# of Stores',
  'UserMeta.user_meta_detail_id': '# of Users',
};

export const mockFilterDataByDimension = {
  current_activity: [
    {
      label: 'Refigure Request',
      value: 'Refigure Request',
    },
    {
      label: 'Reschedule',
      value: 'Reschedule',
    },
    {
      label: 'Ready to Schedule',
      value: 'Ready to Schedule',
    },
    {
      label: 'Work Complete',
      value: 'Work Complete',
    },
    {
      label: 'Submit Documents',
      value: 'Submit Documents',
    },
    {
      label: 'Inspection Results',
      value: 'Inspection Results',
    },
    {
      label: 'Pull Permit',
      value: 'Pull Permit',
    },
    {
      label: 'Schedule Inspection',
      value: 'Schedule Inspection',
    },
    {
      label: 'DD Confirmed',
      value: 'DD Confirmed',
    },
    {
      label: 'Extend Permit Date',
      value: 'Extend Permit Date',
    },
    {
      label: 'Submit LRRP Documentation',
      value: 'Submit LRRP Documentation',
    },
  ],
  current_activity_status: [
    {
      label: 'Open',
      value: 'Open',
    },
    {
      label: 'Completed',
      value: 'Completed',
    },
  ],
};

export const timeFilterOptions = [
  {
    key: 'Today',
    value: 'Today',
  },
  {
    key: 'Yesterday',
    value: 'Yesterday',
  },
  {
    key: 'Tomorrow',
    value: 'Tomorrow',
  },
  {
    key: 'This week',
    value: 'This Week',
  },
  {
    key: 'This month',
    value: 'This Month',
  },
  {
    key: 'This year',
    value: 'This Year',
  },
  {
    key: 'Last 3 days',
    value: 'Last 3 days',
  },
  {
    key: 'Last 7 days',
    value: 'Last 7 days',
  },
  {
    key: 'Last 14 days',
    value: 'Last 14 days',
  },
  {
    key: 'Last 30 days',
    value: 'Last 30 days',
  },
  {
    key: 'Last 365 days',
    value: 'Last 365 days',
  },
  {
    key: 'Last week',
    value: 'Last Week',
  },
  {
    key: 'Last month',
    value: 'Last Month',
  },
  {
    key: 'Last quarter',
    value: 'Last Quarter',
  },
  {
    key: 'Last 100000 days',
    value: 'Till Today',
  },
  {
    key: 'From last 365 days to now',
    value: 'From 365 days ago to now',
  },
  {
    key: 'Next 365 days',
    value: 'Next 365 days',
  },
  {
    key: 'Next 30 days',
    value: 'Next 30 days',
  },
  {
    key: 'Next 14 days',
    value: 'Next 14 days',
  },
  {
    key: 'Next 7 days',
    value: 'Next 7 days',
  },
  {
    key: 'Next 3 days',
    value: 'Next 3 days',
  },
  {
    key: 'From now to 5 days from now',
    value: 'Within 5 Days',
  },
  {
    key: 'From now to 3 days from now',
    value: 'Within 3 Days',
  },
  {
    key: 'Next x days',
    value: 'Next X Days',
    custom: 'daysField',
  },
  {
    key: 'Last x days',
    value: 'Last X Days',
    custom: 'daysField',
  },
  {
    key: 'Empty',
    value: 'null',
    custom: 'nullField',
  },
  {
    key: 'custom',
    value: 'Custom',
    custom: 'datePicker',
  },
];

export const LEAD_RESPONSE_REASON = [
  'Not disrupting 6 sq ft interior or 20 sq feet exterior',
  'Tested Lead Negative',
  'Tested Lead Positive',
  'Written Determination',
  'Home is not pre-1978',
  'Presumed Lead',
  'No Paint Disturbed',
  'Other',
];

export const TYPE_OF_REGISTRATION = {
  sub_contractor: 'Sub Contractor',
  team_member: 'Team Member',
};

export const notificationConfirmationValue = [
  'Receipt acknowledged',
  'Certificate of mail',
  'Certificate attempted delivery',
];

export const workAreasContainedValue = [
  'Objects removed or covered',
  'HVAC ducts closed and covered',
  'Windows and doors closed and sealed',
  'Floor surfaces covered 6ft beyond work area',
  'Doors closed and sealed',
  'Doors used were covered',
  'Work area floors covered',
  'Windows within 20 ft closed',
  'Doors within 20 ft closed and sealed',
  'Exterior ground properly covered',
  'Vertical containment installed',
];

export const PROJECT_TIER_DROPDOWN = [
  'Detail / Work Order',
  'Team Install',
  'Detail',
  'Tier 1',
  'Tier 2',
  'dishwasher',
  'otr microwave',
  'simple vent hood',
  'toilet',
  'ceiling fan',
  'light fixture',
  'work order',
  'single vanity',
  'sink disconnect/reconnect',
  'garbage disposal',
  'faucet',
  'cooktop',
  'shower door',
  'wall oven',
  'gas range',
  'gas dryer',
  'double vanity',
  'complex vent',
];

export const TEXT_COLOR_BLACK = '#000';

export const PROJECT_TYPES = {
  INSTALLATION: 'Installation',
  MEASUREMENT: 'Measurement',
  WORK_ORDER: 'Work Order',
  TENTATIVE_SCHEDULE: 'Tentative Schedule',
  SCHEDULE: 'Schedule',
  UNASSIGNED_INSTALLER: 'Unassigned Installer',
  ADDITIONAL_SCHEDULE: 'Additional Schedule',
};

export const ALLOWED_TYPES_FOR_NEED_SUPPORT_TAB = [
  'Measurement',
  'Installation',
  'Work Order',
];

export const NEEDS_SUPPORT_CATEGORIES = {
  MEASUREMENT: ['Refunds', 'Customers'],
  INSTALLATION: [
    'Labor',
    'Products',
    'Refunds',
    'Missing Documents',
    'Customers',
    'Permits',
  ],
  WORK_ORDER: [
    'Labor',
    'Products',
    'Refunds',
    'Missing Documents',
    'Customers',
    'Permits',
  ],
};

export const NEEDS_SUPPORT_LABELS = {
  PRODUCTS: 'Products',
  LABOR: 'Labor',
  REFUNDS: 'Refunds',
  MISSING_DOCUMENTS: 'Missing Documents',
  CUSTOMERS: 'Customers',
  PERMITS: 'Permits',
  DAMAGED_PRODUCT: 'Damaged product',
  PRODUCT_NOT_READY_AT_JOBSITE: 'Product not ready at jobsite',
  PRODUCT_NOT_READY_AT_STORE: 'Product not ready at store',
  MISSING_OR_INCORRECT_PRODUCT: 'Missing or incorrect product',
  NO_PRODUCT_ATTACHED: 'No product attached',
};

export const NEEDS_SUPPORT_TYPES = {
  PRODUCTS: [
    'Missing or incorrect product',
    'Damaged product',
    'Product not ready at jobsite',
    'Product not ready at store',
    'No product attached',
  ],
  LABOR: ['Mileage', 'Trip Fee', 'Missing or incorrect labor'],
  REFUNDS: ['Full refund', 'Partial refund', 'Permit refund'],
  MISSING_DOCUMENTS: [
    'Provide designs',
    'Provide proposals',
    'Provide contract',
  ],
  CUSTOMERS: ['Unable to contact customer', 'Incorrect contact information'],
  PERMITS: ['Add permit'],
};

export const NEED_SUPPORT_PAYMENT_TYPE = {
  DAMAGED_PRODUCT: ['Trip Fee', 'Wait Fee', 'Both trip and wait fees', 'None'],
  PRODUCT_NOT_READY_AT_JOBSITE: ['Trip Fee'],
  PRODUCT_NOT_READY_AT_STORE: [
    'Trip Fee',
    'Wait Fee',
    'Both trip and wait fees',
    'None',
  ],
  MISSING_OR_INCORRECT_PRODUCT: [
    'Trip Fee',
    'Wait Fee',
    'Both trip and wait fees',
    'None',
  ],
  NO_PRODUCT_ATTACHED: ['Trip Fee'],
};
export const DEFAULT_COLORS_BY_PROJECT_TYPE =
  clientDetails?.project_type_color_config || {};

export const CONFIG = {
  ENABLE_CUSTOMER_LIST: REACT_APP_ENABLE_CUSTOMER_LIST,
  API_BASE_URL: URL_CONSTANTS.API.BASE_URL,
  GOOGLE_MAP_KEY: REACT_APP_GOOGLE_MAPS_KEY,
  API_CRM_URL: URL_CONSTANTS.CRM.baseUrl,
  API_PROJECT_URL: URL_CONSTANTS.PROJECTS.baseUrl,
  API_INVENTORY_URL: URL_CONSTANTS.INVENTORY.baseUrl,
};

export const DOCUMENT_PHOTO_TYPE = {
  DOCUMENT: 1,
  PHOTO: 3,
};

export const DISTANCE_UNIT_OPTIONS = [
  { id: 'miles', label: 'Miles' },
  { id: 'kms', label: 'KMs' },
];

export const USER_STATUS_OPTIONS = [
  { statusIds: 18, label: 'Active' },
  { statusIds: 19, label: 'Inactive' },
];

export const HD_DOC_NUMBERS_FOR_DOCUMENTS = [
  { id: 100, name: 'Measure' },
  { id: 801, name: '105 Receipt of Lead Safe Pamphlet' },
  { id: 300, name: '300 Install Worksheet' },
  { id: 500, name: '500 Install Inspection' },
  { id: 650, name: '650 Customer Approval (ICA)' },
  { id: 651, name: '651 Customer Agreement (HIA)' },
  { id: 652, name: '652 Warranty' },
  { id: 653, name: '653 Collections' },
  { id: 750, name: '750 Change Order' },
  { id: 753, name: '753 Permit' },
  { id: 801, name: '801 LSWP Lead Safe Form' },
  { id: 802, name: '802 LSWP Acknowledgement of Notification' },
  { id: 803, name: '803 LSWP Acknowledgement of Remediation' },
  { id: 804, name: '804 LSWP Renovation Acknowledgement' },
  { id: 805, name: '805 LSWP Renovation Notice' },
  { id: 806, name: '806 LSWP Tenant Notification' },
  { id: 807, name: '807 LSWP RRP Rule' },
  { id: 808, name: '808 LSWP Pre-Existing Conditions' },
  { id: 810, name: '810 LSWP Release and Covenant Pre-Services Work' },
  { id: 811, name: '811 LSWP Release and Covenant Pre-Existing Lead' },
  { id: 812, name: '812 LSWP Project Notification ' },
  { id: 813, name: '813 LSWP Area Drawing (Non-Actionable)' },
  { id: 814, name: '814 LSWP House Built Verification' },
  { id: 815, name: '815 LSWP Lead Free Certification Statement Copy' },
  { id: 951, name: '951 Tax Forms' },
  { id: 999, name: '999 Others' },
];

export const HD_DOC_NUMBERS_FOR_PHOTOS = [
  { id: 100, name: 'Measure' },
  { id: 954, name: 'Site Photo' },
];

export const SOURCE_SYSTEMS = {
  LOWES: 1,
  HOME_DEPOT: 2,
  LUMBER_LIQUIDATOR: 3,
};

export const SOURCE_STATUS = {
  OPEN: 'Open',
  CLOSED: 'Closed',
};

export const HD_CATEGORY = {
  BLINDS: 'Blinds',
};

export const VIEW_NOTE_TYPES = [
  {
    note_category_id: 1,
    category_name: 'Installer',
  },
  {
    note_category_id: 2,
    category_name: 'Retailer',
  },
  {
    note_category_id: 3,
    category_name: 'Store',
  },
  {
    note_category_id: 4,
    category_name: 'Internal ',
  },
  {
    note_category_id: 5,
    category_name: 'Item',
  },
  {
    note_category_id: 6,
    category_name: 'Reschedule',
  },
  {
    note_category_id: 7,
    category_name: 'Busted',
  },
  {
    note_category_id: 8,
    category_name: 'Scheduled',
  },
];

export const LL_DOC_TYPE_FOR_PHOTOS = [
  { label: 'InstallPhotos', value: 'InstallPhotos' },
  { label: 'MeasurePhotos', value: 'MeasurePhotos' },
];

export const LL_DOC_TYPE_FOR_DOCUMENTS = [
  { label: 'All_Doc_WITH_CO', value: 'All_Doc_WITH_CO' },
  { label: 'All_Doc_WITO_CO', value: 'All_Doc_WITO_CO' },
  { label: 'CustCompletion', value: 'CustCompletion' },
  { label: 'InstallChangeAuth', value: 'InstallChangeAuth' },
  { label: 'InstLienWaiver', value: 'InstLienWaiver' },
  { label: 'InstWorkOrder', value: 'InstWorkOrder' },
  { label: 'Invoice', value: 'Invoice' },
  { label: 'LeadSafe', value: 'LeadSafe' },
  { label: 'MoistureReading', value: 'MoistureReading' },
  { label: 'IPProjectAssess', value: 'IPProjectAssess' },
  { label: 'The Satisfaction Form', value: 'The Satisfaction Form' },
];

export const ISSUES_STATUS_OPTION = [
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Escalate',
    value: 'ESCALATE',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
];

export const PRIMARY_COLOR = '#454cb2';
export const PF_ADMIN = REACT_APP_PF_ADMIN_ID;

export const scheduleInfoUserSkipAction = 'skip';
export const scheduleInfoUserWipeAction = 'delete';
export const scheduleInfoUserFillAction = 'fill';
export const scheduleInfoUserCustomAction = 'custom';

export const VALID_MEASURES = {
  Address: [
    'Address.count',
    'Address.address_id',
    'Address.latitude',
    'Address.longitude',
  ],
  Client: ['Client.count', 'Client.client_id'],
  Companies: [
    'Companies.count',
    'Companies.company_id',
    'Companies.address_id',
  ],
  'Compare Payment Request': [
    'ComparePaymentRequest.count',
    'ComparePaymentRequest.payment_request_id',
    'ComparePaymentRequest.client_id',
    'ComparePaymentRequest.project_id',
    'ComparePaymentRequest.payment_status_id',
    'ComparePaymentRequest.check_amount',
    'ComparePaymentRequest.requested_amount',
    'ComparePaymentRequest.qb_rec_id',
    'ComparePaymentRequest.total_sale_amount',
  ],
  'Confirmation Status': [
    'ConfirmationStatus.status_id',
    'ConfirmationStatus.status',
  ],
  'Credential Type': [
    'CredentialType.count',
    'CredentialType.credential_type_id',
  ],
  'Hd Survey': ['HdSurvey.count', 'HdSurvey.hd_survey_id'],
  Customer: [
    'Customer.count',
    'Customer.customer_id',
    'Customer.billing_address_id',
    'Customer.refferal_source_name_id',
    'Customer.refferal_account_source_id',
  ],
  'Customer Address': [
    'CustomerAddress.count',
    'CustomerAddress.address_id',
    'CustomerAddress.latitude',
    'CustomerAddress.longitude',
  ],
  'Customer Email': [
    'CustomerEmail.count',
    'CustomerEmail.customer_email_id',
    'CustomerEmail.customer_id',
  ],
  'Customer Phones': [
    'CustomerPhones.count',
    'CustomerPhones.customer_phone_id',
    'CustomerPhones.customer_id',
    'CustomerPhones.phone_type_id',
  ],
  'Installer Detail': [
    'InstallerDetail.count',
    'InstallerDetail.installer_id',
    'InstallerDetail.client_id',
    'InstallerDetail.shirt_size_id',
  ],
  'Installer Work Type': [
    'InstallerWorkType.count',
    'InstallerWorkType.worktypeid',
    'InstallerWorkType.worktype',
  ],
  'Inventory Adjustment': [
    'InventoryAdjustment.count',
    'InventoryAdjustment.inventory_adjustment_id',
    'InventoryAdjustment.product_id',
    'InventoryAdjustment.warehouse_id',
    'InventoryAdjustment.quantity_to_adjust',
    'InventoryAdjustment.converted_quantity_to_adjust',
    'InventoryAdjustment.cost_of_adjustment_purchase',
    'InventoryAdjustment.cost_of_adjustment_sale',
    'InventoryAdjustment.purchase_price_per_unit',
    'InventoryAdjustment.sales_price_per_unit',
  ],
  'Inventory Product': [
    'InventoryProduct.count',
    'InventoryProduct.inventory_product_id',
    'InventoryProduct.product_id',
    'InventoryProduct.source_price',
    'InventoryProduct.sales_price',
    'InventoryProduct.purchase_price',
    'InventoryProduct.total_quantity_in_hand',
    'InventoryProduct.total_quantity_available',
    'InventoryProduct.total_quantity_allocated',
    'InventoryProduct.threshold',
    'InventoryProduct.sales_price_per_unit',
    'InventoryProduct.purchase_price_per_unit',
    'InventoryProduct.total_purchase_amount',
    'InventoryProduct.total_sales_amount',
  ],
  InventoryTransferFrom: [
    'InventoryTransferFrom.count',
    'InventoryTransferFrom.master_warehouse_id',
    'InventoryTransferFrom.product_id',
    'InventoryTransferFrom.address_id',
    'InventoryTransferFrom.sold_quantity',
    'InventoryTransferFrom.lost_quantity',
    'InventoryTransferFrom.delivered_quantity',
    'InventoryTransferFrom.damaged_quantity',
    'InventoryTransferFrom.available_quantity',
    'InventoryTransferFrom.allotted_quantity',
    'InventoryTransferFrom.actual_quantity',
    'InventoryTransferFrom.actual_purchased_stock_amount',
    'InventoryTransferFrom.actual_sales_stock_amount',
    'InventoryTransferFrom.allocated_purchased_stock_amount',
    'InventoryTransferFrom.allocated_sales_stock_amount',
    'InventoryTransferFrom.available_purchased_stock_amount',
    'InventoryTransferFrom.available_sales_stock_amount',
    'InventoryTransferFrom.damaged_purchased_stock_amount',
    'InventoryTransferFrom.damaged_sales_stock_amount',
    'InventoryTransferFrom.delivered_purchased_stock_amount',
    'InventoryTransferFrom.delivered_sales_stock_amount',
    'InventoryTransferFrom.lost_purchased_stock_amount',
    'InventoryTransferFrom.lost_sales_stock_amount',
    'InventoryTransferTo.count',
    'InventoryTransferTo.master_warehouse_id',
    'InventoryTransferTo.product_id',
    'InventoryTransferTo.address_id',
    'InventoryTransferTo.sold_quantity',
    'InventoryTransferTo.lost_quantity',
    'InventoryTransferTo.delivered_quantity',
    'InventoryTransferTo.damaged_quantity',
    'InventoryTransferTo.available_quantity',
    'InventoryTransferTo.allotted_quantity',
    'InventoryTransferTo.actual_quantity',
    'InventoryTransferTo.actual_purchased_stock_amount',
    'InventoryTransferTo.actual_sales_stock_amount',
    'InventoryTransferTo.allocated_purchased_stock_amount',
    'InventoryTransferTo.allocated_sales_stock_amount',
    'InventoryTransferTo.available_purchased_stock_amount',
    'InventoryTransferTo.available_sales_stock_amount',
    'InventoryTransferTo.damaged_purchased_stock_amount',
    'InventoryTransferTo.damaged_sales_stock_amount',
    'InventoryTransferTo.delivered_purchased_stock_amount',
    'InventoryTransferTo.delivered_sales_stock_amount',
    'InventoryTransferTo.lost_purchased_stock_amount',
    'InventoryTransferTo.lost_sales_stock_amount',
  ],
  'Inventory Transfer': [
    'InventoryTransfer.count',
    'InventoryTransfer.inventory_transfer_id',
    'InventoryTransfer.product_id',
    'InventoryTransfer.transfer_from_warehouse_id',
    'InventoryTransfer.transfer_to_warehouse_id',
    'InventoryTransfer.quantity',
  ],
  'Inventory Warehouse Stock': [
    'InventoryWarehouseStock.count',
    'InventoryWarehouseStock.inventory_warehouse_stock_id',
    'InventoryWarehouseStock.product_id',
    'InventoryWarehouseStock.warehouse_id',
    'InventoryWarehouseStock.actual_quantity',
    'InventoryWarehouseStock.allotted_quantity',
    'InventoryWarehouseStock.available_quantity',
    'InventoryWarehouseStock.damaged_quantity',
    'InventoryWarehouseStock.delivered_quantity',
    'InventoryWarehouseStock.lost_quantity',
    'InventoryWarehouseStock.sold_quantity',
    'InventoryWarehouseStock.purchased_price_per_unit',
    'InventoryWarehouseStock.sales_price_per_unit',
    'InventoryWarehouseStock.actual_purchased_stock_amount',
    'InventoryWarehouseStock.actual_sales_stock_amount',
    'InventoryWarehouseStock.allocated_purchased_stock_amount',
    'InventoryWarehouseStock.allocated_sales_stock_amount',
    'InventoryWarehouseStock.available_purchased_stock_amount',
    'InventoryWarehouseStock.available_sales_stock_amount',
    'InventoryWarehouseStock.damaged_purchased_stock_amount',
    'InventoryWarehouseStock.damaged_sales_stock_amount',
    'InventoryWarehouseStock.delivered_purchased_stock_amount',
    'InventoryWarehouseStock.delivered_sales_stock_amount',
    'InventoryWarehouseStock.lost_purchased_stock_amount',
    'InventoryWarehouseStock.lost_sales_stock_amount',
    'InventoryWarehouseStock.inventory_product_total_quantity_in_hand',
    'InventoryWarehouseStock.inventory_product_total_quantity_available',
    'InventoryWarehouseStock.inventory_product_total_quantity_allocated',
    'InventoryWarehouseStock.inventory_product_total_sales_amount',
    'InventoryWarehouseStock.inventory_product_total_purchase_amount',
    'InventoryWarehouseStock.inventory_product_purchase_price_per_unit',
    'InventoryWarehouseStock.inventory_product_sales_price_per_unit',
    'InventoryWarehouseStock.inventory_product_threshold',
    'InventoryWarehouseStock.inventory_product_purchase_price',
    'InventoryWarehouseStock.inventory_product_sales_price',
    'InventoryWarehouseStock.inventory_product_source_price',
  ],
  'Issue Category': [
    'IssueCategory.count',
    'IssueCategory.issue_category_id',
    'IssueCategory.category',
  ],
  Item: [
    'Item.count',
    'Item.item_id',
    'Item.client_id',
    'Item.item_type_id',
    'Item.cost_price',
    'Item.retail_price',
    'Item.inventory_managment',
    'Item.source_price',
    'Item.warehouse_stock_actual_quantity',
    'Item.item_quantity',
    'Item.inventory_product_total_quantity_in_hand',
    'Item.inventory_product_total_quantity_available',
    'Item.inventory_product_total_quantity_allocated',
    'Item.warehouse_stock_allotted_quantity',
    'Item.warehouse_stock_available_quantity',
    'Item.warehouse_stock_damaged_quantity',
    'Item.warehouse_stock_delivered_quantity',
    'Item.warehouse_stock_lost_quantity',
    'Item.warehouse_stock_sold_quantity',
    'Item.inventory_product_total_sales_amount',
    'Item.inventory_product_total_purchase_amount',
    'Item.inventory_product_purchase_price_per_unit',
    'Item.inventory_product_sales_price_per_unit',
    'Item.inventory_product_threshold',
    'Item.inventory_product_purchase_price',
    'Item.inventory_product_sales_price',
    'Item.inventory_product_source_price',
    'Item.inventory_product_total_quantity_lost',
    'Item.inventory_product_total_quantity_sold',
    'Item.inventory_product_total_quantity_damaged',
    'Item.inventory_product_total_quantity_purchased',
  ],
  'Lowes Survey': [
    'LowesSurvey.count',
    'LowesSurvey.lowes_survey_id',
    'LowesSurvey.po_number',
    'LowesSurvey.client_id',
    'LowesSurvey.installer_name',
    'LowesSurvey.survey_date',
    'LowesSurvey.comments',
    'LowesSurvey.district_number',
    'LowesSurvey.store_number',
    'LowesSurvey.store_name',
    'LowesSurvey.professionalism',
    'LowesSurvey.craftsmanship',
    'LowesSurvey.ltr_score',
    'LowesSurvey.qb_sur_id',
  ],
  Warehouse: [
    'Warehouse.count',
    'Warehouse.master_warehouse_id',
    'Warehouse.product_id',
    'Warehouse.address_id',
    'Warehouse.sold_quantity',
    'Warehouse.lost_quantity',
    'Warehouse.delivered_quantity',
    'Warehouse.damaged_quantity',
    'Warehouse.available_quantity',
    'Warehouse.allotted_quantity',
    'Warehouse.actual_quantity',
    'Warehouse.actual_purchased_stock_amount',
    'Warehouse.actual_sales_stock_amount',
    'Warehouse.allocated_purchased_stock_amount',
    'Warehouse.allocated_sales_stock_amount',
    'Warehouse.available_purchased_stock_amount',
    'Warehouse.available_sales_stock_amount',
    'Warehouse.damaged_purchased_stock_amount',
    'Warehouse.damaged_sales_stock_amount',
    'Warehouse.delivered_purchased_stock_amount',
    'Warehouse.delivered_sales_stock_amount',
    'Warehouse.lost_purchased_stock_amount',
    'Warehouse.lost_sales_stock_amount',
  ],
  'Notes Category': ['NotesCategory.count', 'NotesCategory.note_category_id'],
  'Payment Request': [
    'PaymentRequest.count',
    'PaymentRequest.payment_request_id',
    'PaymentRequest.client_id',
    'PaymentRequest.project_id',
    'PaymentRequest.check_amount',
    'PaymentRequest.requested_amount',
  ],
  'Payment Status': ['PaymentStatus.count', 'PaymentStatus.status_id'],
  'Pr Type': ['PrType.count', 'PrType.pr_type_id', 'PrType.type_name'],
  Project: [
    'Project.count',
    'Project.project_id',
    'Project.confirmation_status_id',
    'Project.customer_id',
    'Project.epa_add_lead_safe_practices_item',
    'Project.epa_is_lead_assessment_docs_submitted',
    'Project.epa_is_renovator_certified',
    'Project.epa_lead_remediation',
    'Project.epa_lead_response_reason_id',
    'Project.epa_lead_test_result_id',
    'Project.epa_override_reason_code_id',
    'Project.epa_permit_required',
    'Project.epa_remove_override',
    'Project.is_pick_up_date_provided',
    'Project.no_of_messages_sent',
    'Project.pick_up_location_id',
    'Project.project_category_id',
    'Project.project_type_id',
    'Project.source_status_id',
    'Project.source_system_id',
    'Project.status_id',
    'Project.store_id',
    'Project.total_labor_amount',
    'Project.total_sale_amount',
    'Project.pickup_required',
    'Project.is_routed',
    'Project.products',
    'Project.adjusted_allocated_quantity',
    'Project.available_to_schedule_turn_time',
    'Project.sold_to_product_available_turn_time',
    'Project.available_to_start_turn_time',
    'Project.sold_to_complete_turn_time',
    'Project.product_available_to_complete_turn_time',
    'Project.cost_of_adjustment_purchase',
    'Project.cost_of_adjustment_sale',
    'Project.purchase_price_per_unit',
    'Project.sales_price_per_unit',
    'Project.total_allocated',
    'Project.adjusted_delivered_quantity',
    'Project.project_warranty_service_contract_amount',
    'Project.retail_price',
    'Project.cost_price',
    'Project.item_capacity',
    'Project.item_allocation_percentage',
    'Project.item_number',
    'Project.item_desc',
    'Project.item_type_id',
    'Project.cost_ea',
    'Project.project_item_labor_cost_ea',
    'Project.project_item_labor_cost_ea_value',
    'Project.item_quantity',
    'Project.total_cost_ea',
    'Project.project_item_margin',
    'Project.item_user_percentage',
    'Project.item_user_total_cost',
    'Project.item_user_quantity',
    'Project.item_user_cost',
    'Project.labor_cost_ea',
    'Project.labor_cost_ea_value',
    'Project.item_technician_completion_date',
    'Project.note_text',
    'Project.item_total_sell_price',
    'Project.sell_price',
    'Project.project_item_user_margin',
    'Project.item_technician_status_value',
    'Project.profit',
    'Project.item_technician_profit',
    'Project.item_profit',
    'Project.source_price',
    'Project.retail_price',
    'Project.cost_price',
    'Project.inventory_product_threshold',
    'Project.total_labor_profit',
    'Project.total_labor_retail',
    'Project.total_labor_cost',
    'Project.total_labor_margin',
    'Project.total_merchandise_profit',
    'Project.total_merchandise_cost',
    'Project.total_merchandise_retail',
    'Project.total_merchandise_margin',
    'Project.total_retail_cost',
    'Project.total_overall_profit',
    'Project.total_overall_margin',
    'Project.total_revenue',
    'Project.total_commission',
    'Project.total_cost',
    'Project.material_chargeback',
    'Project.labor_chargeback',
    'Project.total_retail',
    'Project.total_technician',
    'Project.payment_count',
    'Project.payment_amount_in_emi',
    'Project.payment_amount_balance',
    'Project.payout_amount',
    'Project.rts_to_doc_submit_turn_time',
    'Project.work_start_to_doc_submit_turn_time',
    'Project.work_complete_to_doc_submit_turn_time',
    'Project.sold_to_product_available_turn_time',
    'Project.question_id',
    'Project.module_id',
    'Project.answer',
    'Project.project_item_sales_commission',
    'Project.aging',
    'Project.project_item_quantity',
    'Project.installer_sales_commission',
    'Project.total_exclude_from_commission',
    'Project.close_rate',
    'Project.related_install_amount',
    'Project.key_rec_cost',
  ],
  'Project Hd Order Info': ['ProjectHDOrderInfo.key_rec_cost'],
  'Project Activity': [
    'ProjectActivity.count',
    'ProjectActivity.project_activity_id',
    'ProjectActivity.project_id',
  ],
  'Project Additional Charges': [
    'ProjectAdditionalCharges.count',
    'ProjectAdditionalCharges.project_additional_charge_id',
    'ProjectAdditionalCharges.project_id',
    'ProjectAdditionalCharges.pr_type_id',
    'ProjectAdditionalCharges.pr_date',
    'ProjectAdditionalCharges.pr_ims_note',
    'ProjectAdditionalCharges.dollar_amount',
    'ProjectAdditionalCharges.pr_number',
    'ProjectAdditionalCharges.store_associate',
    'ProjectAdditionalCharges.processed',
  ],
  'Project Additional Fields': [
    'ProjectAdditionalFields.count',
    'ProjectAdditionalFields.project_additional_field_id',
    'ProjectAdditionalFields.client_id',
    'ProjectAdditionalFields.project_id',
    'ProjectAdditionalFields.sold_to_product_available_turn_time',
    'ProjectAdditionalFields.available_to_schedule_turn_time',
    'ProjectAdditionalFields.available_to_start_turn_time',
    'ProjectAdditionalFields.sold_to_complete_turn_time',
    'ProjectAdditionalFields.product_available_to_complete_turn_time',
    'ProjectAdditionalFields.ll_vendor_id',
    'ProjectAdditionalFields.ll_job_status',
    'ProjectAdditionalFields.ll_sr_job_status',
    'ProjectAdditionalFields.ll_job_accepted',
    'ProjectAdditionalFields.ll_measure_req_sent',
    'ProjectAdditionalFields.ll_install_paper_date',
    'ProjectAdditionalFields.ll_pgi_invoice_date',
    'ProjectAdditionalFields.related_measurer',
    'ProjectAdditionalFields.close_rate',
    'ProjectAdditionalFields.related_install_amount',
  ],
  'Project Category': [
    'ProjectCategory.count',
    'ProjectCategory.project_category_id',
  ],
  'Project Installer': [
    'ProjectInstaller.count',
    'ProjectInstaller.project_installer_id',
    'ProjectInstaller.project_id',
    'ProjectInstaller.installer_id',
    'ProjectInstaller.labor_amount',
    'ProjectInstaller.client_id',
    'ProjectInstaller.worktypeid',
  ],
  'Project Issues': [
    'ProjectIssues.count',
    'ProjectIssues.project_issue_id',
    'ProjectIssues.project_id',
    'ProjectIssues.issue_category_id',
    'ProjectIssues.dollar_amt',
  ],
  'Project Item': [
    'ProjectItem.count',
    'ProjectItem.project_item_id',
    'ProjectItem.project_id',
    'ProjectItem.item_id',
    'ProjectItem.status_id',
    'ProjectItem.item_quantity',
    'ProjectItem.cost_ea',
    'ProjectItem.labor_cost_ea',
    'ProjectItem.labor_cost_percentage',
    'ProjectItem.client_id',
    'ProjectItem.item_number',
    'ProjectItem.warehouse_stock_actual_quantity',
    'ProjectItem.inventory_product_total_quantity_in_hand',
    'ProjectItem.inventory_product_total_quantity_available',
    'ProjectItem.inventory_product_total_quantity_allocated',
    'ProjectItem.warehouse_stock_allotted_quantity',
    'ProjectItem.warehouse_stock_available_quantity',
    'ProjectItem.warehouse_stock_damaged_quantity',
    'ProjectItem.warehouse_stock_delivered_quantity',
    'ProjectItem.warehouse_stock_lost_quantity',
    'ProjectItem.warehouse_stock_sold_quantity',
    'ProjectItem.sales_commission',
  ],
  'Project Note': [
    'ProjectNote.count',
    'ProjectNote.project_note_id',
    'ProjectNote.project_id',
    'ProjectNote.note_status_id',
    'ProjectNote.note_type_id',
    'ProjectNote.closed_by',
    'ProjectNote.store_id',
  ],
  'Project Permit': [
    'ProjectPermit.count',
    'ProjectPermit.project_permit_id',
    'ProjectPermit.project_id',
    'ProjectPermit.status_id',
    'ProjectPermit.is_emergency_permit',
    'ProjectPermit.is_inspection_required',
    'ProjectPermit.is_permit_required',
  ],
  'Project Status': ['ProjectStatus.count', 'ProjectStatus.status_id'],
  'Project Support': [
    'ProjectSupport.count',
    'ProjectSupport.project_support_id',
    'ProjectSupport.project_id',
    'ProjectSupport.support_type_id',
  ],
  'User Details': [
    'ProjectTaskNoteUserCreatedBy.count',
    'ProjectTaskNoteUserCreatedBy.user_meta_detail_id',
    'ProjectTaskNoteUserCreatedBy.role_id',
    'ProjectTaskUserAssignedTo.count',
    'ProjectTaskUserAssignedTo.user_meta_detail_id',
    'ProjectTaskUserAssignedTo.role_id',
    'ProjectTaskUserCreatedBy.count',
    'ProjectTaskUserCreatedBy.user_meta_detail_id',
    'ProjectTaskUserCreatedBy.role_id',
    'TaskNoteUserCreatedBy.count',
    'TaskNoteUserCreatedBy.user_meta_detail_id',
    'TaskNoteUserCreatedBy.role_id',
    'TaskUserCreatedBy.count',
    'TaskUserCreatedBy.user_meta_detail_id',
    'TaskUserCreatedBy.role_id',
    'UserMeta.count',
    'UserMeta.user_meta_detail_id',
    'UserMeta.role_id',
  ],
  'Project Task Notes': ['ProjectTaskNotes.count', 'ProjectTaskNotes.task_id'],
  'Project Tasks': ['ProjectTasks.count', 'ProjectTasks.task_id'],
  'Project Type': ['ProjectType.count', 'ProjectType.project_type_id'],
  'Project Warranty': [
    'ProjectWarranty.count',
    'ProjectWarranty.project_warranty_id',
    'ProjectWarranty.project_id',
    'ProjectWarranty.service_contract_amount',
  ],
  'Shirt Sizes': ['ShirtSizes.count', 'ShirtSizes.shirt_size_id'],
  'Shoe Sizes': ['ShoeSizes.count', 'ShoeSizes.shoe_size_id'],
  'Source Status': ['SourceStatus.count', 'SourceStatus.status_id'],
  'Source System': ['SourceSystem.count', 'SourceSystem.source_system_id'],
  Store: [
    'Store.count',
    'Store.store_id',
    'Store.source_system_id',
    'Store.store_number',
    'Store.address_id',
    'Store.is_deleted',
    'Store.is_private',
  ],
  'Support Type': [
    'SupportType.count',
    'SupportType.support_type_id',
    'SupportType.support_category',
    'SupportType.type_name',
  ],
  'Task Category': ['TaskCategory.count', 'TaskCategory.category_id'],
  'Task Notes': ['TaskNotes.count', 'TaskNotes.task_id'],
  Tasks: ['Tasks.count', 'Tasks.task_id'],
  Teams: ['Teams.count', 'Teams.team_id'],
  'User Lowes Registration': [
    'UserLowesRegistration.count',
    'UserLowesRegistration.user_lrrp_detail_id',
    'UserLowesRegistration.user_id',
    'UserLowesRegistration.client_id',
    'UserLowesRegistration.team_member_id',
    'UserLowesRegistration.license_status',
  ],
  Users: ['Users.count', 'Users.user_id'],
  Lead: [
    'Opportunity.count',
    'Opportunity.opportunity_id',
    'Opportunity.opportunity_number',
    'Opportunity.customer_id',
    'Opportunity.category_id',
    'Opportunity.source_id',
    'Opportunity.lead_owner_user_id',
    'Opportunity.conversion_rate',
    'Opportunity.quote_exists',
    'Opportunity.project_conversion_ratio',
    'Opportunity.source_status',
    'Opportunity.lead_to_quote_turn_on',
    'Opportunity.quote_to_project_turn_on',
    'Opportunity.lead_to_project_turn_on',
    'Opportunity.contact_person',
    'Opportunity.primary_phone',
    'Opportunity.primary_email',
    'Opportunity.description',
    'Opportunity.alternative_phone',
    'Opportunity.alternative_email',
    'Opportunity.status_id',
    'Opportunity.referral_source',
    'Opportunity.type_id',
    'Opportunity.last_name',
    'Opportunity.created_at',
    'Opportunity.modified_at',
    'Opportunity.quote_number',
    'Opportunity.quote_created_date',
    'Opportunity.quote_followup_date',
    'Opportunity.quote_status',
    'Opportunity.quote_to_project_conversion',
    'Opportunity.project_exists',
  ],
  Quotes: [
    'Quotes.count',
    'Quotes.created_at',
    'Quotes.expiry_date',
    'Quotes.unit_price',
    'Quotes.quote_id',
    'Quotes.total_price',
    'Quotes.amount_to_be_paid_after_service',
    'Quotes.quantity',
    'Quotes.discount',
    'Quotes.tax',
    'Quotes.total_price',
    'Quotes.discount_type',
    'Quotes.tax',
    'Quotes.total_price',
    'Quotes.item_desc',
    'Quotes.item_type',
    'Quotes.opportunity_number',
    'Quotes.project_exists',
    'Quotes.quote_to_project_conversion',
    'Quotes.lead_category_id',
    'Quotes.lead_owner_user_id',
    'Quotes.lead_status_id',
    'Quotes.lead_source_status',
  ],
  Chargebacks: [
    'Chargebacks.count',
    'Chargebacks.chargeback_id',
    'Chargebacks.material_chargeback',
    'Chargebacks.labor_chargeback',
    'Chargebacks.total_retail',
    'Chargebacks.total_technician',
    'Chargebacks.payment_count',
    'Chargebacks.payment_amount_in_emi',
    'Chargebacks.payment_amount_balance',
    'Chargebacks.payout_amount',
  ],
  'Chargeback Reasons': [
    'ChargebackReasons.count',
    'ChargebackReasons.chargeback_reason_id',
  ],
  'Chargeback Payouts': [
    'ChargebackPayouts.count',
    'ChargebackPayouts.chargeback_payout_id',
    'ChargebackPayouts.payment_amount',
  ],
  'Chargeback Payment Categories': [
    'ChargebackPaymentCategories.count',
    'ChargebackPaymentCategories.payment_category_id',
  ],
  Retainage: [
    'Retainage.count',
    'Retainage.retainage_id',
    'Retainage.total_amount',
    'Retainage.pay_percent',
    'Retainage.fixed_amount',
    'Retainage.remaining_balance',
    'Retainage.payout_amount',
  ],
  'Retainage Categories': [
    'RetainageCategories.count',
    'RetainageCategories.category_id',
  ],
  'Retainage Payouts': [
    'RetainagePayouts.count',
    'RetainagePayouts.payout_id',
    'RetainagePayouts.payment_amount',
  ],
  'Retainage Types': ['RetainageTypes.count', 'RetainageTypes.type_id'],
  Crews: ['Crews.count', 'Crews.crew_id', 'Crews.capacity'],
  'Crew Types': ['CrewTypes.count', 'CrewTypes.type_id'],
  'Crew Members': ['CrewMembers.count', 'CrewMembers.member_id'],
  'Chargeback Status': ['ChargebackStatus.count', 'ChargebackStatus.status_id'],
  Checklist: [
    'Checklist.count',
    'Checklist.question_id',
    'Checklist.module_id',
    'Checklist.answer',
  ],
};
export const DATE_OPERATORS = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  TOMORROW: 'Tomorrow',
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
  THIS_YEAR: 'This year',
  TILL_TODAY: 'Till today',
  FROM_365_DAYS_TO_NOW: 'From last 365 days to now',
};

export const scheduleInfoUserAction = {
  FULL_ALLOCATION_ITEMS: 'delete',
  ALLOCATE_REMAINING_ITEMS: 'fill',
  LEAVE_UNASSIGNED_ITEMS: 'skip',
  CUSTOM_ALLOCATION: 'custom',
};

export const alphanumericRegex = /^[0-9A-Z]+$/;

// Options for Retaiange Frequency Type
export const RetainageFrequencyTypeOptions = [
  {
    key: 'daily',
    value: 'Daily',
  },
  {
    key: 'weekly',
    value: 'Weekly',
  },
  {
    key: 'bi_weekly',
    value: 'Bi-Weekly',
  },
  {
    key: 'monthly',
    value: 'Monthly',
  },
  {
    key: 'yearly',
    value: 'Yearly',
  },
];

// Options for Member Status
export const MemberStatusOptions = [
  {
    status: 'active',
    value: 'Active',
  },
  {
    status: 'inactive',
    value: 'Inactive',
  },
  {
    status: 'on_leave',
    value: 'On Leave',
  },
];
export const postRenovationCleaningMethod = [
  'Cleaning verification',
  'Dust clearance',
];

export const nonAvailableReason = 'N/A';

export const PROJECT_TYPE = {
  MEASUREMENT: 1,
  INSTALLATION: 2,
  WORKORDER: 3,
};

export const COMMISSION_WORK_TYPE = {
  ID: 25,
  LABEL: 'Commission',
};

export const LL_INVOICE_STATUS_TYPE = [
  { status: 'not_invoiced', value: 'Not Invoiced' },
  { status: 'invoice_sent', value: 'Invoice Sent' },
];

// Options for Payout type
export const RETAINAGE_PAYOUT_TYPE_OPTION = [
  {
    key: 'fixed',
    value: 'Fixed',
  },
  {
    key: 'percentage',
    value: 'Percentage',
  },
  {
    key: 'payrollPercentage',
    value: 'Percentage of Payroll',
  },
];

// Options for Payout type
export const CHARGEBACK_PAYOUT_TYPE_OPTION = [
  ...RETAINAGE_PAYOUT_TYPE_OPTION,
  {
    key: 'collectFromRetainage',
    value: 'Collect from Retainage',
  },
];

export const CHARGEBACK_TYPE = {
  PayrollOfPercentage: 'Percentage of Payroll',
};

export const ENVIRONMENT_VARIABLES = {
  auditTabEnabled: REACT_APP_AUDIT_TAB_ENABLED,
  configAuditTabEnabled: REACT_APP_CONFIGURATION_AUDIT_LOG_ENABLED,
};

// Options for Date Criteria
export const DateCriteria = [
  {
    key: 'scheduled',
    value: 'Scheduled',
  },
  {
    key: 'invoiced',
    value: 'Invoiced',
  },
  {
    key: 'projectCompletion',
    value: 'Project Completion',
  },
  {
    key: 'lineItemCompletion',
    value: 'Line Item Completion',
  },
];

export const projectUserStatusId = 255;
export const SALES_COMMISSION_TYPE = [
  { label: 'Total Retail $', value: 'Total Retail $' },
  { label: 'Total Revenue $', value: 'Total Revenue $' },
  { label: 'Total Cost $', value: 'Total Cost $' },
  { label: 'Labor Retail $', value: 'Labor Retail $' },
  { label: 'Total Sales $', value: 'Total Sales $' },
];

export const ROLE_EDIT_DISABLE_ID = [1, 2, 3];

export const QuestionTypes = [
  {
    id: 'rating5',
    label: 'Rating 1 - 5',
  },
  {
    id: 'rating10',
    label: 'Rating 1 - 10',
  },
  {
    id: 'list',
    label: 'List',
  },
  {
    id: 'text',
    label: 'Text',
  },
];

export const ESTIMATED_MAX_LIMIT = 1000000;
// Options for Payroll type
export const PayrollType = ['labor', 'chargeback', 'retainage'];

// Options for Payroll footers
export const PayrollFooters = [
  { key: 'projFooter', label: 'Project: Total' },
  { key: 'approvedFooter', label: 'Project: Approved' },
  { key: 'excludeFooter', label: 'Project: Exclude' },
  { key: 'balanceFooter', label: 'Project: Balance' },
];

// Options for Payroll paid unpaid
export const PayrollReviewType = ['paid', 'unpaid'];

export const ItemLinkageConfigOptions = [
  { name: 'Sync Technician', value: 'technician' },
  { name: 'Sync Scheduled Date & Time', value: 'schedule' },
  { name: 'Sync Documents', value: 'documents' },
];

export const project_linkages = {
  options: [],
  force_sync: false,
  enabled: false,
};
// Options for Payroll records
export const PayrollRecordType = ['excluded', 'unexcluded'];

export const ExportedReportsStatus = [
  { key: 'ACTIVE', className: 'text-blue-400' },
  { key: 'INACTIVE', className: 'text-600' },
  { key: 'INPROGRESS', className: 'text-yellow-400' },
  { key: 'DONE', className: 'text-green-400' },
  { key: 'FAILED', className: 'text-red-400' },
  { key: 'DELETED', className: 'text-red-400' },
];

export const ReportDateDiff = 14;

export const PayrollReportFilters = [
  { key: 'searchBy', value: 'Search By' },
  { key: 'workroom', value: 'Workroom' },
  { key: 'store', value: 'Store Name' },
  { key: 'category', value: 'Category' },
  { key: 'type', value: 'Type' },
  { key: 'status', value: 'Status' },
  { key: 'technician', value: 'Technician' },
  { key: 'technicianType', value: 'Technician Type' },
  { key: 'chargeback', value: 'Chargeback' },
  { key: 'retainage', value: 'Retainage' },
  { key: 'labor', value: 'Labor' },
  { key: 'exclude', value: 'Exclude' },
  { key: 'paymentStatus', value: 'Approved' },
];
export const DURATION_TYPES = [
  { label: 'Minute', value: 'minute' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];
export const libraries = ['places'];

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const WORK_HOURS = [
  {
    day: 'Monday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
    is_working: true,
  },
  {
    day: 'Tuesday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
    is_working: true,
  },
  {
    day: 'Wednesday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
    is_working: true,
  },
  {
    day: 'Thursday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
    is_working: true,
  },
  {
    day: 'Friday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
    is_working: true,
  },
  {
    day: 'Saturday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
    is_working: true,
  },
  {
    day: 'Sunday',
    start_time: '08:00',
    end_time: '17:00',
    break_start_time: '12:00',
    break_end_time: '13:00',
  },
];

export const ProjectReviewStatus = {
  REFUNDREVIEWED: 'Refund_Reviewed',
};
export const AddNewColor = {
  ADDNEWCOLOR: 0,
};

export const workTypeConstants = {
  SCHEDULED: 1,
};

export const PermitsStatusTypeId = {
  APPLICATIONSUBMITTED: 137,
};

export const CX_SCHEDULED_STATUS = 'Customer Scheduled';
export const homeDepotOrder = {
  HD_ORDER: 'hd_order',
  SF_AND_I_ORDER: 'sf_and_i_order',
  HD_LEAD: 'hd_lead',
  BLINDS_ORDER: 'blinds_order',
};
export const ImageFormatType = ['image/jpeg', 'image/png', 'image/jpg'];

export const csvUploadConfig = {
  PF_CSV_UPLOAD_SIZE: REACT_PF_CSV_UPLOAD_SIZE || 512000,

  ERROR_MESSAGES: {
    POST_UPLOADED_DATA_SUCCESS: 'File uploaded successfully',
    FILE_TYPE_ERROR: 'Only CSV file are allowed.',
    FILE_SIZE_ERROR: `File size exceeds the maximum limit of ${
      (REACT_PF_CSV_UPLOAD_SIZE || 512000) / 1024
    } KB.`,
    FILE_EXISTS: `File already uploaded.`,
    FILE_NOT_SUPPORTED: `File not supported.`,
  },
};
export const DocFileTypeError = {
  DOC_FILE_TYPE_ERROR:
    'Only .pdf, .doc, .docx, .xls, .xlsx, and .csv files are allowed',
  DOC_FILE_LIMIT_ERROR: 'Please select up to 5 files only.',
};
export const Limit = {
  MAX_LIMIT: 10,
};
export const DateSold = 'Project.date_sold';

export const BULLETIN = {
  TOPBAR: 1,
  HOME: 2,
};
export const ROLES = {
  ADMIN: 'Admin',
  INSTALLER: 'Installer',
  STAFF: 'Staff',
};

export const PERMISSION_TYPES = {
  USER_ASSIGNED: 'user_assigned',
  MODIFY: 'modify',
};

export const EMPLOYMENT_TYPES = [
  { title: 'Employee', value: 'Employee' },
  { title: 'Subcontractor', value: 'Subcontractor' },
  { title: 'Candidate', value: 'Candidate' },
];
export const formAction = {
  ADD: 'add',
  EDIT: 'edit',
};

export const projectInfoCategory = {
  ADD_CATEGORY: 'Add New Category',
};

export const INVENTORY = {
  OPTIONS_FOR_ITEM: [
    { id: 1, name: 'Add', value: 'Add' },
    { id: 2, name: 'Lost', value: 'Lost' },
    { id: 3, name: 'Damaged', value: 'Damaged' },
  ],
  OPTIONS_FOR_PROJECT: [
    { id: 1, name: 'Add', value: 'Add' },
    { id: 2, name: 'Lost', value: 'Lost' },
    { id: 3, name: 'Damaged', value: 'Damaged' },
    { id: 6, name: 'Allocated', value: 'Allocated' },
    { id: 7, name: 'On Order', value: 'OnOrder' },
  ],
  OPTIONS_FOR_ADJUSTMENT_TYPE: [
    { id: 1, name: 'Add', value: 'Add' },
    { id: 2, name: 'Lost', value: 'Lost' },
    { id: 3, name: 'Damaged', value: 'Damaged' },
    { id: 5, name: 'Dispatched', value: 'Dispatched' },
    { id: 6, name: 'Allocated', value: 'Allocated' },
    { id: 7, name: 'On Order', value: 'OnOrder' },
  ],
};
export const statusData = [{ value: 'Open' }, { value: 'Completed' }];

export const severityData = [
  { name: 'High', icon: 'pi pi-angle-up' },
  { name: 'Medium', icon: 'pi pi-align-justify' },
  { name: 'Low', icon: 'pi pi-angle-down' },
];

export const MAPLINK_BASE = 'https://www.google.com/maps/';
export const TECHNICIAN_FILTER_MESSAGE = `Allocate items using "Full Allocation" for 100% or "Custom Allocation" with your inputs or "Allocate Remaining" for unassigned parts or "Leave Unassigned".`;

export const ALLOCATION_PROJECT_ITEMS = [
  'FULL_ALLOCATION_ITEMS',
  'CUSTOM_ALLOCATION',
  'ALLOCATE_REMAINING_ITEMS',
  'LEAVE_UNASSIGNED_ITEMS',
];

export const SchedulerCustomFilters = {
  CUSTOMTYPE: 'AC',
  ADMIN: 'configuration',
  USER: 'usermanagement',
};
export const PROJECT_REPORT = {
  CUSTOMERS: 'customers',
  WORKROOMID: 'workroomId',
  CALENDAR: 'calendar',
  SPLIT: 'split',
  FULL_CALENDAR: 'fullCalendar',
  CONTAINED: 'contained',
  MAP: 'map',
  PROJECT: 'Project',
  CATEGORY: 'category',
};

export const SCHEDULER_MAP = [
  { name: 'Map', value: 'ROADMAP' },
  { name: 'Satellite', value: 'SATELLITE' },
];

export const CHARGEBACK_DEFAULT_STATUS = {
  status_id: 425,
};

export const JOB_STATUS = {
  SCHEDULED: 'Scheduled',
};
export const INPUT_NUMBER_LOCALE = 'en-US';

export const UNASSIGNED = {
  UNASSIGNED: 'unassigned',
};
export const TECHNICIAN_TYPES = {
  SCHEDULED: 'schedule',
};

export const ITEM_TYPES = {
  labor: 2,
  product: 6,
};

export const ParentItemFilterOptions = [
  { label: 'All Parent', value: 'is_parent' },
  { label: 'All Child', value: 'is_child' },
];

export const openDocumentInfo = {
  is_offline: false,
  count: 1,
  device_id: '',
  platform: 'web',
};
