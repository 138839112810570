import axios from 'axios';
import moment from 'moment-timezone';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getNewSchedulerInitialData = async datePeriodForTechnicianView => {
  try {
    const defaultQueryString = `date=${moment(new Date()).format()}&period=${datePeriodForTechnicianView}`;

    const EventsRequest = ApiService.get(
      `/scheduler/calendar-view?${defaultQueryString}`
    );

    const eventsResponse = await EventsRequest;
    return {
      eventsList: eventsResponse?.items,
    };
  } catch (error) {
    console.error(error);
  }
};

export const getNewSchedulerData = async (
  queryParams,
  additionalParams = {}
) => {
  const { date, period } = queryParams;
  try {
    let queryString = `date=${date}&period=${period}`;
    if (
      additionalParams.projectTypeIds &&
      additionalParams.projectCategoryId &&
      additionalParams.projectStoreId
    ) {
      queryString += `&projectTypeIds=${additionalParams.projectTypeIds}`;
      queryString += `&projectCategoryId=${additionalParams.projectCategoryId}`;
      queryString += `&projectStoreId=${additionalParams.projectStoreId}`;
      if (additionalParams.isJobCoverage) {
        queryString += `&isJobCoverage=${additionalParams.isJobCoverage}`;
      }
    }

    const eventsResponse = await ApiService.get(
      `/scheduler/calendar-view?${queryString}`
    );
    return eventsResponse?.items;
  } catch (error) {
    console.error(error);
  }
};

export const getProjectDataById = async projectId => {
  try {
    const response = await ApiService.get(
      `/scheduler/map/${projectId}/edit-schedule`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getRescheduleReasonOptions = async sourceId => {
  try {
    const response = await ApiService.get(
      `/system/reason?reasonType=Project&sourceSystemId=${sourceId}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateProjectScheduleInfo = async (
  projectId,
  updatedScheduleData
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      updatedScheduleData
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getAllInstallersList = async () => {
  try {
    const response = await ApiService.get(`/auth/user/report/get-installers`);
    const installerList = response.map(i => {
      return {
        name: `${i.first_name} ${i.last_name}`,
        id: i.installer_id,
        installerUserId: i.user_id,
      };
    });
    return installerList;
  } catch (error) {
    console.error(error);
  }
};

export const addInstallerUnavailablilty = async (
  installerId,
  unavailabilityPayload
) => {
  const eventPayload = {
    ...unavailabilityPayload,
    full_day: false,
    is_recurring: false,
    is_recurring_every_other_week: 0,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thus: false,
    fri: false,
    sat: false,
  };
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/availability`,
      eventPayload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateSchedule = async updateObj => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/update`,
      updateObj
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getProjectStatusList = async () => {
  try {
    const response = await ApiService.get(`/system/status?statusType=Project`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
